<template>
  <dashboard-layout>
    <loader-animation v-if="isLoading" />
    <div class="w-full p-5" v-if="!isLoading">
      <div class="p-4">
        <div class="flex mb-4 justify-between">
          <h1 class="text-3xl">
            Regionais
          </h1>
          <router-link
            class="bg-green-1000 text-white px-6 py-3 rounded rounded-md focus:outline-none"
            :to="{ name: 'create-regional' }"
          >
            Nova Regional
          </router-link>
        </div>
        <v-client-table
          ref="table"
          :data="regionals"
          :columns="columns"
          :options="options"
        >
          <div slot="telephone" slot-scope="{ row }">
            <the-mask
              class="text-center outline-none bg-transparent"
              mask="(##) ####-####"
              :value="row.telephone"
              readonly
            ></the-mask>
          </div>
          <div slot="status" slot-scope="{ row }">
            {{ row.status == 1 ? 'Ativo' : 'Inativo' }}
          </div>
          <div class="flex" slot="actions" slot-scope="{ row, index }">
            <router-link
              class="flex p-1 border-2 border-green-1002 w-max rounded-lg mr-4"
              :to="{ name: 'edit-regional', params: { id: row.id } }"
              v-tooltip="{ content: 'Editar', placement: 'left' }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M12.9 6.858l4.242 4.243L7.242 21H3v-4.243l9.9-9.9zm1.414-1.414l2.121-2.122a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414l-2.122 2.121-4.242-4.242z"
                  fill="rgba(0,122,111,1)"
                />
              </svg>
            </router-link>
            <router-link
              class="flex p-1 border-2 border-green-1002 w-max rounded-lg mr-4"
              :to="{ name: 'manage-regional-access', params: { id: row.id } }"
              v-tooltip="{ content: 'Gerenciar Acessos', placement: 'left' }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M2 22a8 8 0 1 1 16 0H2zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm7.363 2.233A7.505 7.505 0 0 1 22.983 22H20c0-2.61-1-4.986-2.637-6.767zm-2.023-2.276A7.98 7.98 0 0 0 18 7a7.964 7.964 0 0 0-1.015-3.903A5 5 0 0 1 21 8a4.999 4.999 0 0 1-5.66 4.957z"
                  fill="rgba(0,122,111,1)"
                />
              </svg>
            </router-link>
            <button
              class="flex p-1 border-2 border-green-1002 w-max rounded-lg"
              @click="deleteRegional(row, index)"
              v-tooltip="{ content: 'Deletar', placement: 'left' }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M17 6h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3zm-8 5v6h2v-6H9zm4 0v6h2v-6h-2zM9 4v2h6V4H9z"
                  fill="rgba(0,122,111,1)"
                />
              </svg>
            </button>
          </div>
        </v-client-table>
      </div>
    </div>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '../../../layouts/DashboardLayout';
import axios from '@/utils/axios';
import { TheMask } from 'vue-the-mask';
import LoaderAnimation from '../../../components/LoaderAnimation.vue';

export default {
  name: 'Index',

  title() {
    return `${process.env.VUE_APP_NAME} | Regionais`;
  },

  components: {
    DashboardLayout,
    TheMask,
    LoaderAnimation
  },

  data() {
    return {
      isLoading: false,
      regionals: [],
      columns: ['id', 'name', 'telephone', 'status', 'actions'],
      options: {
        sortable: true,
        perPage: 10,
        perPageValues: [10, 15, 20, 25, 50],
        headings: {
          name: 'Nome',
          telephone: 'Telefone',
          status: 'Status',
          actions: 'Ações'
        },
        hiddenColumns: ['id'],
        sortIcon: {
          is: 'glyphicon-sort',
          base: 'glyphicon',
          up: 'glyphicon-chevron-up',
          down: 'glyphicon-chevron-down'
        },
        texts: {
          first: 'Primeiro',
          last: 'Último',
          filter: 'Filtrar:',
          filterBy: 'Filtrar por {column}',
          filterPlaceholder: 'Pesquise aqui...',
          count:
            '{from} - {to} de {count} registros|{count} registros|Um registro',
          limit: 'Registros por página:',
          page: 'Página:',
          noResults: 'Nenhum registro encontrado.',
          columns: 'Colunas'
        },
        pagination: {
          chunk: 5
        }
      }
    };
  },
  methods: {
    fetchRegionals() {
      this.isLoading = true;
      axios.get('/api/regionais').then(({ data }) => {
        data.data.forEach(regional => {
          this.regionals.push(regional);
          this.isLoading = false;
        });
      });
    },

    deleteRegional(row, index) {
      this.$confirm({
        message: 'Tem certeza que deseja excluir esta regional?',
        button: {
          no: 'Não',
          yes: 'Sim'
        },
        callback: confirm => {
          if (confirm) {
            axios.delete(`/api/regionais/${row.id}`).then(() => {
              this.regionals.splice(index - 1, 1);
            });
          }
        }
      });
    }
  },
  created: function() {
    this.fetchRegionals();
  }
};
</script>

<style scoped></style>
